<template>
  <Page :title="title" class="c-content-page">
    <section id="container-form-parent">
      <!-- Content Page Form Header -->
      <Header
        :action-disabled="actionDisabled"
        :action-label="actionLabel"
        :cancel-label="cancelLabel"
        :loading="loading"
        :no-header="noHeader"
        :title="title"
        :isDirty="isDirty"
        @save="save"
        @cancel="cancel"
      >
        <template #breadcrumb>
          <slot name="breadcrumb" />
        </template>

        <template #header v-if="$slots.header">
          <slot name="header" />
        </template>

        <template #actions v-if="$slots.actions">
          <slot name="actions" />
        </template>
      </Header>

      <!-- Content Page Form Body -->
      <Content :loading="loading" :formId="formId" :schema="schema" :initialValue="initialValues">
        <template #content>
          <Form
            id="content-page-form"
            :form-id="formId"
            :schema="schema"
            :initialValues="initialValues"
            @onValidateSuccess="emit('primaryAction', $event)"
            @onValidateFail="handleValidationFail($event)"
            ref="formComponent"
          >
            <template v-slot="{ isFormValid, form }">
              <slot name="content" />
            </template>
          </Form>
        </template>
      </Content>
    </section>
  </Page>
</template>

<script setup lang="ts">
import { computed, ref, type PropType } from 'vue';
import { Page } from '../../../core/containers/pages/page';
import type { Schema, TagComponentProp } from './../../../../../types';
import Form from '../form/Form.vue';
import Content from './components/content/Content.vue';
import Header from './components/header/Header.vue';

// Define emits
const emit = defineEmits(['primaryAction', 'validationFail', 'cancel']);

// Define props
defineProps({
  title: {
    type: String,
  },
  actionLabel: {
    type: String,
  },
  cancelLabel: {
    type: String,
  },
  actionDisabled: {
    type: Boolean,
    default: false,
  },
  tagComponents: {
    type: Object as PropType<TagComponentProp[]>,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  noHeader: {
    type: Boolean,
    default: false,
  },
  formId: {
    type: String,
    required: true,
  },
  schema: {
    type: Object as PropType<Schema>,
    required: true,
    default: () => ({}) as Schema,
  },
  initialValues: {
    type: Object,
  },
});

// Define refs for form component
const formComponent = ref<InstanceType<typeof Form>>();

// Handle validation fail event
const handleValidationFail = (args: any[]): void => {
  emit('validationFail', args);
};

// Submit form
const save = async (): Promise<void> => {
  formComponent.value?.validate();
};

// Computed form dirty state
const isDirty = computed<boolean>(() => formComponent.value?.isDirty);

// Cancel callback function
const cancel = (): void => {
  emit('cancel');
};
</script>
