import { type RouteRecordRaw, RouterView } from 'vue-router';
// import { route as homeRoute } from '@centric-os/home';
import { route as brandsRoute } from '@centric-os/global-menu-groups';
import { route as menusRoute } from '@centric-os/local-menu-groups';
import { route as loginRoute } from '@centric-os/login';
import { route as ordersRoute } from '@centric-os/orders';
import { route as reportsRoute } from '@centric-os/reports';
import { route as sitesRoute } from '@centric-os/sites';
import { route as scannerRoute } from '@centric-os/scanner';
import { route as librariesRoute } from '@centric-os/libraries';

// Announcements Routes
import { route as announcementsRoutes } from '@centric-os/announcements';

// Admin Panel Routes
import { route as ap3UsersRoute } from '@ap3/user-management';

import { createRouter } from '@centric-os/platform/router';
import { RootRouteNames, RootRoutePathNames } from '../enums';
import authGuard from './guards/auth';
import { appcuesIdentify, appcuesGuard } from './guards/app-cues';

const MainContent = async () => {
  const module = await import('./../components/main-content');
  return module.MainContent;
};

export const experimentRoutes: RouteRecordRaw[] = [
  {
    path: RootRoutePathNames.ROOT,
    name: RootRouteNames.ROOT,
    component: RouterView,
    children: [
      loginRoute,
      {
        path: '',
        name: RootRouteNames.MAIN,
        component: MainContent,
        redirect: () => {
          return sitesRoute.path;
        },
        children: [
          // homeRoute,
          sitesRoute,
          ordersRoute,
          reportsRoute,
          brandsRoute,
          menusRoute,
          scannerRoute,
          librariesRoute,

          // Announcements Routes
          announcementsRoutes,

          // Admin Panel Routes
          ap3UsersRoute,
        ],
      },
    ],
  } as RouteRecordRaw,
];

export const router = createRouter(experimentRoutes);

router.beforeEach(authGuard);
router.beforeEach(appcuesGuard);
router.afterEach(appcuesIdentify);
