import type { LocalMenuGroup } from '../brand';
import type { V2MenuHours, MenuMeta } from '../menu';
import { SortOrder, type PaginationConfig, type RecordEntityId } from './../records/index';

export interface Site {
  // group
  id?: RecordEntityId;
  name?: string;
  label?: {
    en?: string;
    fr?: string;
  };
  // Distance in meters from the group
  distance?: number;
  locations?: SiteLocation[];
  address?: Address;
  meta?: {
    [index: string]: any;
  };
  style?: any;
  [index: string]: any;

  // new site properties from edge API
  location_group?: any[];
  app?: string;
  sector?: string;
  unit_number?: string;
  visible_stations?: number;
  menu_sources?: string[];
  local_menu_group?: Partial<LocalMenuGroup>[];
}

export interface Address {
  // Suite number of the address, if applicable
  suite?: string;
  // First line of the address, should include street number
  address?: string;
  // City of the address
  city?: string;
  // State of the address, also province in Canada
  state?: string;
  // Country of the address
  country?: string;
  // Zip code or postal code of the address
  zip?: string;
  coordinates?: {
    // latitude of the the address
    latitude?: number;
    // longitude of the the address
    longitude?: number;
  };
}
export interface SiteLocation {
  // location
  id?: string;
  operation_id?: number;
  name?: string;
  label?: {
    en?: string;
    fr?: string;
  };
  search?: string[];
  app?: string;
  address?: Address;
  latitude?: number;
  longitude?: number;
  brands?: StationBrand[];
  meta?: {
    [index: string]: any;
    app_name: string;
    unit: number;
    unit_id: number;
  };
  phone?: string;
  // group
  location_group?: string;
  // multigroup
  location_multigroup?: string;
  // sector
  sector?: string;
  date?: {
    deleted?: string;
    created?: string;
    modified?: string;
  };
  market_place?: {
    label?: {
      en?: string;
      fr?: string;
    };
    location_description?: {
      en?: string;
      fr?: string;
    };
    logo?: string | File;
    is?: {
      enabled?: boolean;
      pickup_supported?: boolean;
      delivery_supported?: boolean;
    };
    delivery_details?: {
      show_single_timeslot?: boolean;
      show_instructions?: boolean;
      runner_app_enabled?: boolean;
    };
    delivery_destinations?: string[];
    pickup_instruction?: {
      en?: string;
      fr?: string;
    };
    hours?: any;
    deliveryHours?: any;
    service_fee?: {
      type?: string;
      value?: number;
    };
    [index: string]: any;
  };
  [index: string]: any;
}

interface StationBrandMeta {
  scout?: {
    user_id: string;
    name: {
      first: string;
      last: string;
    };
  };
  partner?: {
    user_id: string;
    name: {
      first: string;
      last: string;
    };
  };
  contact?: {
    name: string;
    role: string;
    email: string;
    phone: string;
  };
  market_id: string;
  partner_id: string;
  business_number: number;
  website: string;
  cuisine_types: string[];
  status: string[];
  active: boolean;
  max_showcase_items: number;
  frictionless_store_uuid: string;
  menu: MenuMeta;
  type_of_kds?: Record<string, boolean>;
  has_kds?: boolean;
  software_integrations?: SoftwareIntegrations;
  frictionless_third_party_partner?: string;
}

export interface BusinessUnitsKDS {
  units: KDSUnit[];
}

export interface KDSUnit {
  kdsDevices: KDSDevice[];
  unitNumber: string;
}

export interface KDSDevice {
  id: number;
  name: string;
  serialNumber: string;
  brands: string[];
}

export interface KDSDeviceWithUnit extends KDSDevice {
  unitNumber: string;
}

export interface SoftwareIntegrations {
  system365?: string;
  jwo?: string;
}
export interface Is {
  pickup_supported?: boolean;
  delivery_supported?: boolean;
  plu_enabled?: boolean;
  promo_exemptions_enabled?: boolean;
  local_images_enabled?: boolean;
  hidden?: boolean;
  show_in_kiosk?: boolean;
  show_in_pos?: boolean;
  item_desc_edit_enabled?: boolean;
  scan_and_go_supported?: boolean;
  frictionless_supported?: boolean;
  cafe360?: boolean;
  special_instructions_enabled: boolean;
}
// device_mapping for integration partners
export enum IntegrationPartner {
  VOLANTE = 'Volante',
  SAVOUR = 'Savour',
  NEXTEP = 'Nextep',
  COMPASS_KDS = 'CDL',
  JWO = 'JWO',
  MASHGIN = 'Mashgin',
  STANDARD_AI = 'Standard AI',
  THRIVE = 'Thrive',
  SCAN_AND_PAY = 'Scan & Pay',
  BOOST = 'Boost',
  // Add other integration partner types as needed
}
export enum KdsVendor {
  COMPASS_KDS = 'cdl',
  NEXTEP = 'nextep',
  VOLANTE = 'volante',
  AGILYSYS = 'agilysys',
}
export interface Device {
  device_type: DeviceType;
  site_device_id: RecordEntityId;
  business_units?: RecordEntityId[];
  vendor?: IntegrationPartner | string;
}

export type DeviceTypeVendorMapping = {
  [key in DeviceType]?: DeviceMapping;
};

export type DeviceMapping = {
  [key in IntegrationPartner]?: Device[];
} & Record<string, Device[]>;

export type SiteDevices = {
  site_devices: DeviceMapping;
};

export interface NextepDevice {
  device_type: DeviceType;
  site_device_id: RecordEntityId;
  business_units?: RecordEntityId[];
}

export enum DeviceType {
  KIOSK = 'KIOSK',
  POS = 'POS',
  OMS = 'OMS',
  DMD = 'DMD',
}

export interface DeviceArrays {
  [DeviceType.KIOSK]?: Device[];
  [DeviceType.POS]?: Device[];
  [DeviceType.OMS]?: Device[];
  [DeviceType.DMD]?: Device[];
}

export interface NextepDevice {
  Type: DeviceType;
  Id: RecordEntityId;
  RevenueCenter: string;
}

export interface DeliveryUserDefined {
  start_time: string | Date;
  end_time: string | Date;
  delivery_destinations: string[];
}

export interface Timeslot {
  time?: string;
  duration_minutes?: number;
  customers_per_slot?: number;
  menu_items_per_slot?: number;
  averagePrepTime?: string;
  delivery_time?: string;
  delivery_customers_per_slot?: number;
  delivery_menu_items_per_slot?: number;
  delivery_is_user_defined: boolean;
  delivery_user_defined?: DeliveryUserDefined[];
  delivery_prep_time?: string;
  delivery_destinations?: string[];
}

export interface Hours {
  id: string;
  date: {
    start: string;
    end: string;
  };
  day: {
    start: number;
    end: number;
  };
  hours: string;
}
export interface StationBrandLabel {
  en: string;
  fr?: string;
}

export interface StationBrand {
  [key: string]: any;
  id: string;
  address: Address;
  brand?: string;
  deliveryHours?: Hours[];
  estimated_wait_time?: StationBrandWaitTime;
  group: string;
  hours: Hours[];
  sector?: string;
  name?: string;
  label?: StationBrandLabel;
  timezone?: string;
  description?: string;
  latitude?: number;
  longitude?: number;
  menus?: V2MenuHours[];
  date?: {
    created?: string;
  };
  style?: any;
  pos?: string;
  terminals?: any[];
  device_mapping?: DeviceMapping;
  timeslots?: Timeslot;
  is?: Is;
  location?: string;
  payment_provider?: string;
  location_description?: string;
  company?: string;
  config?: {
    private?: any;
    public?: any;
  };
  tax_rate?: number;
  meta?: StationBrandMeta;
  descriptions?: {
    location?: {
      en?: string;
      fr?: string;
    };
  };
  color?: {
    enabledColor: string;
    disabledColor: string;
  };
}

export interface FileUpload {
  file_base64?: string;
  url?: string;
}

export interface StationBrandWaitTime {
  [key: string]: {
    max: number;
    min: number;
    ready_time: number;
  };
}
export const enum TenderTypes {
  DECLINING_BALANCE = 'declining_balance',
  MEAL_EXCHANGE = 'meal_exchange',
  MEAL_EQUIVALENT = 'meal_equivalent',
}
export const enum MealplanTypes {
  ATRIUM = 'ATRIUM',
  TRANSACT_BLACKBOARD = 'BLACKBOARD',
  TRANSACT_BLACKBOARD_SAAS = 'BLACKBOARD SAAS',
  HEARTLAND = 'HEARTLAND',
  ITC_SSO = 'ITCSSO',
  ITC_STANDARD = 'ITCSTANDARD',
  CBORD_WEB = 'CBORD',
  CBORD_DIRECT = 'CBORD DIRECT',
}
export interface TenderConfig {
  tender_type: string;
  name: string;
  tax_exempt: boolean;
}
export interface BadgePayConfig {
  id: string;
  type: string;
  name: string;
  webview: string;
  tax_exempt?: boolean;
}
export interface CashlessConfig {
  id: string;
  type: string;
  name: string;
  webview: string;
  voucher_webview: string;
  tenders: TenderConfig[] | Tender[];
  cashlessTenders?: Tender[]; // Used for form validation
  freedompay?: FreedomPayKeys;
}

export interface FreedomPayKeys {
  badge_pay_system_key?: string;
}

export interface MealplanConfig {
  id: string;
  name: string;
  tenders: Tender[];
  type: string;
  note?: MealPlanNote;
  valid_email_domains: string[];
  terminal_id: string | string[];
  isBrandMEQ: boolean;
  isBrandMX: boolean;
}
export interface SiteConfigPublic {
  promotions: Record<string, any>[];
  badge_pay: BadgePayConfig;
  cashless: CashlessConfig;
  mealplan: Partial<MealPlan>[];
}

export interface DeliveryDestination {
  address: DeliveryDestinationAddress;
  foodlocker: boolean;
  name: string;
  id?: RecordEntityId;
  information?: string;
  sort_sequence?: number;
}

export interface DeliveryDestinationDelete {
  delivery_destination_ids: RecordEntityId[];
}

export interface DeliveryDestinationRequest {
  delivery_destinations: DeliveryDestination[];
}

export interface DeliveryDestinationResponse {
  delivery_destinations: DeliveryDestination[];
}

export interface DeliveryDestinationAddress {
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  coordinates: { latitude: number; longitude: number };
}

export interface StationBrandConfigPublic {
  logos?: {
    print?: string;
  };
  canadian_calorie_disclaimer?: string;
  diasplay_calories?: boolean;
  delivery_destinations?: string[];
  delivery_destinations_automatic_timeslot?: string[];
  has_kds?: boolean;
  get_phone_number?: boolean;
  pickup_asap?: {
    enabled?: boolean;
    max?: number | null;
  };
  show_instructions?: boolean;
  show_single_timeslot?: boolean;
}
export interface ConfigPrivate {
  payment?: PaymentConfig;
  loyalty?: {
    loyalty_emails: [];
    enabled: boolean;
  };
  apex?: {
    apex_integrated: boolean;
    apex_client_numb: string;
  };
  mealplan?: MealPlan[];
  supports?: {
    reorder: boolean;
  };
  meal_periods?: {
    label: string;
    start: string;
    end: string;
  }[];
  email?: string;
  kds?: {
    admin_passcode: string;
  };
  delivery_fee?: {
    type: string;
    value: number;
  };
  web_order?: {
    enabled: boolean;
  };
  excluded_payment_methods?: {
    credit_card: boolean;
    digital_wallet_pay: [];
    meal_swipes: [];
    mealplan: [];
  };
  keystore?: KeystoreConfig;
}

export interface KeystoreConfig {
  payment?: PaymentConfig;
  mealplan?: MealplanKeystore;
}

export interface MealplanKeystore {
  uuid?: string; // CBORD
  auth_url?: string; // CBORD
  name?: string; // CBORD
  type?: string; // CBORD
  auth_type?: number; // CBORD
}

export interface PaymentConfig {
  type?: string;
  freedompay?: FreedomPay;
  braintree?: {
    id: string;
    merchant_account_id: string;
  };
  refund?: boolean;
  is_encrypted?: boolean;
}

export interface PaymentConfigValidation {
  store_id: string;
  terminal_id: string;
}

export interface FreedomPay {
  id?: string;
  badge_pay_system_key?: string;
  freedompay_store_id?: string;
  freedompay_terminal_id?: string;
}

export interface MealPlan extends MealplanConfig {
  keystore?: Keystore;
  config?: AtriumConfig; // Atrium
  note?: MealPlanNote; // TouchNet and Atrium
  tax_exempt?: boolean; // TouchNet
  version?: number; // Atrium
}

export interface Keystore {
  certificate?: Certificate; // ITC
  device_id?: string; // ITC
  endpoint?: string; // ITC
  vendor_id?: string; // ITC
  webview?: string; // ITC
  key?: string; // Atrium
  API_URL?: string; // Atrium
  api_url?: string; // TouchNet
  operator_id?: string; // TouchNet
  operator_password?: string; // TouchNet
  host?: string; // Blackboard
  aes_key?: string; // Blackboard
  port?: number; // Blackboard
  vendor_number?: number; // Blackboard
  manual_id_entry?: string; // Blackboard
  auth_url?: string; // CBORD
  uuid?: string; // CBORD
}

export interface Certificate {
  // ITC
  encoded_file?: string;
  name?: string;
  password?: string;
}

export interface AtriumConfig {
  // Atrium
  terminal_id?: string[] | string;
  version?: string;
  tenders?: Tender[];
}

export interface TransactConfig {
  // Blackboard
  terminal_id?: string | string[];
}

export interface MealPlanNote {
  // Atrium and TouchNet
  [key: string]: string;
}

export interface Tender {
  id?: string;
  name?: string;
  max_swipes_per_transaction?: number;
  tax_exempt?: boolean;
  tender_number?: string;
  type?: string;
  tender_type?: string;
  rates?: TenderRate[];
}

export interface TenderRate {
  amount?: number;
  startTime?: Date;
  endTime?: Date;
  hours?: string;
}

export enum StationType {
  V2 = 'Admin Menu',
  CENTRIC_OS = 'Centric Menu',
}

export const defaultSitePaginationConfig: PaginationConfig = {
  queryParams: {
    limit: 25,
    page: 1,
    sortBy: 'name',
    sortOrder: SortOrder.ASC,
  },
  metaParams: {
    resultCount: 0,
    totalPages: 1,
    totalResults: 0,
  },
};

export interface StructuredStationEvents {
  menuEvents?: StructuredMenuEvents;
  businessEvents?: CalendarEvent[];
  closureEvents?: CalendarEvent[];
  deliveryEvents?: CalendarEvent[];
}
export interface StructuredMenuEvents {
  [key: string]: CalendarEvent[];
}
export interface StationEvents {
  [key: string]: StructuredStationEvents;
}
export interface CalendarObject {
  date: CalendarDate;
  events: CalendarEvent[];
  id: string;
  timezone: string;
}

export interface CalendarEvent {
  data: CalendarEventData;
  schedule: CalendarEventSchedule;
}

export interface CalendarEventData {
  calendar: CalendarTypes;
  color?: string;
  id: string;
  title: string;
  is?: CalendarEventIs;
  menu?: string;
  version: number;
}

export interface CalendarEventIs {
  delivery?: boolean;
  pickup?: boolean;
  frictionless?: boolean;
  hidden?: boolean;
  disabled?: boolean;
}
export interface CalendarEventSchedule {
  dayOfWeek?: number[];
  duration?: number;
  start?: number;
  end?: number;
  every?: { week: number } | boolean;
  year?: number[];
  month?: number[];
  dayOfMonth?: number[];
  times?: string[];
  durationUnit?: string;
}
export interface CalendarDate {
  last_sync_error: number;
  last_sync: number;
}

export const enum CalendarTypes {
  MENU = 'menu',
  BUSINESS = 'business',
  HOLIDAY = 'holiday',
  DELIVERY = 'delivery',
}
export const TypeOfKds = {
  nextep: {
    name: 'Nextep OMS',
    value: 'nextep',
  },
  agilysys: {
    name: 'Agilysys KDS',
    value: 'agilysys',
  },
  volante: {
    name: 'Volanté KDS',
    value: 'volante',
  },
  cdl: {
    name: 'Centric KDS',
    value: 'cdl',
  },
};

export interface ReportingConfig {
  location_group_id: RecordEntityId;
  day_time: Date | string;
  day_parts: DayParts[];
  is_default: boolean;
}

export interface DayParts {
  label: string;
  start: Date | string;
  end: Date | string;
}

export interface StationBrandConfig {
  brands: StationConfig[];
}

export interface StationConfig {
  id: RecordEntityId;
  is: Is;
}

export const enum OrderType {
  PRE_ORDER = 'Pre-Order',
  SCAN_AND_PAY = 'Scan & Pay',
  JWO = 'JWO',
}

export const enum JDE_ENVIRONMENTS {
  PRODUCTION = 'Production',
  PY = 'PY',
  PY_AND_PRODUCTION = 'PY & Production',
}
