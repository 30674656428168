import { DateTime } from 'luxon';
import { SortOrder, type PaginationConfig, type QueryParams } from '../records';
import { AnnouncementViews } from './types';

export interface AnnouncementsPagination extends PaginationConfig {
  queryParams?: AnnouncementsQueryParams;
}

export interface AnnouncementsQueryParams extends QueryParams {
  start_date_start?: Date;
  start_date_end?: Date;
  end_date_start?: Date;
  end_date_end?: Date;
  last_modified_start?: Date;
  selectedView?: AnnouncementViews;
  limit?: number;
  page?: number;
  sortBy?: string;
  sortOrder?: SortOrder;
}

export const announcementsListPaginationConfig: AnnouncementsPagination = {
  queryParams: {
    selectedView: AnnouncementViews.LIVE,
    start_date_start: DateTime.local().minus({ days: 90 }).startOf('day').toJSDate(),
    start_date_end: DateTime.local().endOf('day').toJSDate(),
    end_date_start: DateTime.local().startOf('day').toJSDate(),
    end_date_end: DateTime.local().plus({ days: 90 }).endOf('day').toJSDate(),
    limit: 25,
    page: 1,
    sortBy: 'end_date_end',
    sortOrder: SortOrder.DESC,
  },
  metaParams: {
    resultCount: 0,
    totalPages: 1,
    totalResults: 0,
  },
};
