import {
  defaultSitePaginationConfig,
  type CDLQueryParams,
  type RecordList,
  type Site,
  type RecordEntityId,
  type DeliveryDestination,
  type LocalMenuGroupToSiteRelationship,
  P2Role,
  type DeliveryDestinationResponse,
} from '@centric-os/types';
import type { AxiosRequestConfig } from 'axios';
import { defineStore, storeToRefs, type StateTree } from 'pinia';
import { useAuthStore } from '../../auth';
import { isEmpty } from 'lodash';
import { useKDSStore, useMultigroupStore } from '..';

interface State extends StateTree {
  site: Site;
  sites: Site[];
  siteDeliveryDestinations: DeliveryDestination[];
  updateSiteOnNextMount: boolean;
  siteLocalMenuGroups: LocalMenuGroupToSiteRelationship[];
  // should be temporaly
  loadingAllSites: boolean;
}
export function useSiteStore(storeId = 'site') {
  const store = defineStore(storeId, {
    state: (): State => ({
      site: null,
      sites: [],
      siteDeliveryDestinations: [],
      updateSiteOnNextMount: false,
      siteLocalMenuGroups: [],
      // should be temporaly
      loadingAllSites: false,
    }),
    actions: {
      async getSites(
        noPagination: boolean = false,
        params: CDLQueryParams = {
          sortBy: 'created_at',
          sortOrder: 'DESC',
        },
        config: AxiosRequestConfig = {},
      ): Promise<void> {
        // Stores for user config
        const authStore = useAuthStore();
        const multigroupStore = useMultigroupStore();

        const isSiteOperator = authStore.hasRole(P2Role.SITE_OPERATOR);

        // Fetch multigroups if user is not site operator
        if (!isSiteOperator) {
          await multigroupStore.getMultiGroups();
        }

        if (!noPagination) {
          params = this.pagination.queryParams;
        }

        if (!isEmpty(multigroupStore.getMultigroupIDs)) {
          params = { ...params, id_multigroups: multigroupStore.getMultigroupIDs };
        }

        const response = await this.cdlApi.get<RecordList<Site>>('/centricos/sites', {
          ...config,
          params,
        });
        const { results, meta } = response.data;

        this.sites = results;

        if (!noPagination) {
          this.updatePaginationWithMeta(meta);
        }
      },
      async getSite(siteId: RecordEntityId, extended = true): Promise<void> {
        const response = await this.cdlApi.get<Site>(`/location/group/${siteId}?nocache=1`, {
          params: { extended: extended, include_estimated_wait_time: false },
        });
        this.site = response.data;
      },
      async getSiteDetailsPage(siteId: RecordEntityId, extended = true): Promise<void> {
        await this.cdlApi
          .get<Site>(`/location/group/${siteId}?nocache=1`, {
            params: { extended: extended, include_estimated_wait_time: false },
          })
          .then(async (response) => {
            this.site = response.data;

            // Fetch KDS devices for the site BUs
            const kdsStore = useKDSStore();
            await kdsStore.fetchKDSDevices();

            // Fetch delivery destinations for the site
            await this.getSiteDeliveryDestinations(siteId);
          });
      },
      async getSiteNoExtended(siteId: RecordEntityId): Promise<Site> {
        const response = await this.cdlApi.get<Site>(
          `/location/group/${siteId}?nocache=1&include_estimated_wait_time=false`,
        );
        return response?.data;
      },
      async updateSite(siteId: RecordEntityId, payload: Partial<Site>): Promise<void> {
        const response = await this.cdlApi.patch<Site>(`/location/group/${siteId}`, payload);
        this.site = response.data;
      },
      async getSiteDeliveryDestinations(siteId: RecordEntityId): Promise<void> {
        const response = await this.cdlApi.get<DeliveryDestinationResponse>(
          `/location/group/${siteId}/deliverydestination`,
        );

        // Sort the delivery destinations by sort_sequence without mutating the original array
        const sortedLocations = response?.data?.delivery_destinations?.toSorted(
          (a: DeliveryDestination, b: DeliveryDestination) => a.sort_sequence - b.sort_sequence,
        );

        this.siteDeliveryDestinations = sortedLocations;
      },
      async getSitesLocalMenuGroups(
        siteId: RecordEntityId,
      ): Promise<LocalMenuGroupToSiteRelationship[]> {
        const response = await this.cdlApi.get<RecordList<LocalMenuGroupToSiteRelationship>>(
          `/menu/v3/local-menu-group/${siteId}/site`,
        );
        this.siteLocalMenuGroups = response?.data?.results;
        return this.siteLocalMenuGroups;
      },
      //  This method should be temporaly
      async getSitesForUser(): Promise<void> {
        this.loadingAllSites = true;
        // Stores for user config
        const authStore = useAuthStore();
        const { cdlUser } = storeToRefs(authStore);
        const multigroupStore = useMultigroupStore();
        const { multigroups } = storeToRefs(multigroupStore);

        const isSiteOperator = authStore.hasRole(P2Role.SITE_OPERATOR);

        // Fetch multigroups if user is not site operator
        if (!isSiteOperator && isEmpty(multigroups.value)) {
          await multigroupStore.getMultiGroups();
        }

        const promises = multigroups.value?.map((multigroup) =>
          this.getUserMultigroupSites(multigroup.id, cdlUser.value?.id),
        );

        // Wait for all promises to resolve
        const results = await Promise.all(promises);

        // Flatten the array of arrays into a single array
        const flattenedResultsArray = results.flat().filter((result) => result !== null);

        this.sites = flattenedResultsArray;
        this.loadingAllSites = false;
      },
      // This method should be temporaly
      async getUserMultigroupSites(multigroupId: string, userId: string): Promise<Site[]> {
        const response = await this.cdlApi.get<any>(
          `/location/multigroup/${multigroupId}/user/${userId}`,
          {
            params: {
              expanded: true,
              nocache: true,
            },
          },
        );

        return response.data?.groups || [];
      },
    },
    pagination: true,
    paginationConfig: {
      ...defaultSitePaginationConfig,
    },
  });
  return store();
}
