import type { RouteRecordRaw } from 'vue-router';
import { buildChildURL } from '@centric-os/helpers';
import { AnnouncementsRouteNames, AnnouncementsRoutePathNames } from '../enums';
import { beforeEnter as listGuard } from './guards';

const List = () => import('../components/list/List.vue');

const listRoute = {
  path: buildChildURL(AnnouncementsRoutePathNames.LIST),
  name: AnnouncementsRouteNames.ANNOUNCEMENTS_LIST,
  component: List,
  beforeEnter: listGuard,
} as RouteRecordRaw;

const childRoutes: RouteRecordRaw[] = [listRoute];

export default childRoutes;
