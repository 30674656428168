<template>
  <Grid class="grid-nogutter">
    <Col12>
      <div class="single-column-layout" :class="[fullViewport ? 'full-viewport' : 'default']">
        <Card id="main-content-card" class="layout-card" :class="[CardHelperClasses.NO_SHADOWS]">
          <template #header v-if="$slots?.header">
            <div class="header">
              <slot name="header" />
            </div>
          </template>

          <template #content>
            <div class="content">
              <template v-if="loading">
                <Grid>
                  <Col12>
                    <Skeleton width="70%" class="mb-2" />
                    <Skeleton />
                  </Col12>
                  <Col12>
                    <Skeleton width="70%" class="mb-2" />
                    <Skeleton />
                  </Col12>
                  <Col12>
                    <Skeleton width="70%" class="mb-2" />
                    <Skeleton />
                  </Col12>
                  <Col12>
                    <Skeleton width="70%" class="mb-2" />
                    <Skeleton />
                  </Col12>
                </Grid>
              </template>

              <template v-else>
                <slot name="content" />
              </template>
            </div>
          </template>
        </Card>
      </div>
    </Col12>
  </Grid>
</template>

<script setup lang="ts">
import { Card, CardHelperClasses } from '../../containers';
import { Skeleton } from '../../misc';
import { Col12 } from '../columns';
import { Grid } from '../grids';
import { useContentPageStore } from '@centric-os/stores';
import { storeToRefs } from 'pinia';

// Define options for component name
defineOptions({
  name: 'SingleColumnLayout',
});

// Define props
defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  fullViewport: {
    type: Boolean,
    default: false,
  },
});

// Set content page store for heigh calculation
const contentPageStore = useContentPageStore();
const { contentPageLayoutHeight } = storeToRefs(contentPageStore);
</script>

<style lang="scss" scoped>
.single-column-layout {
  padding-left: 7.25rem;
  padding-right: 7.25rem;
  padding-top: 2rem;

  &.full-viewport {
    height: v-bind('contentPageLayoutHeight');
    max-height: v-bind('contentPageLayoutHeight');

    .default {
      max-height: v-bind('contentPageLayoutHeight');
    }
  }
}
</style>
