export enum LocalMenuGroupRoutePathNames {
  LOCAL_MENUS = 'local-menus',
  BRAND_DETAILS = 'brand',
  LIST = '',
  ID = ':id',
  BRAND_ID = ':brandId',
  MENUS = 'menus',
  MENUS_ID = ':menuId?',
  ITEMS = 'items',
  MODIFIERS = 'modifiers',
  UPDATES = 'updates',
  ACTIVITY_LOG = 'activity-log',
}
